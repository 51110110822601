<template>
  <v-form ref="form" lazy-validation>
    <v-card>
      <v-container fluid>
        <v-row class="mt-3 text-center">
          <v-col>
            <span class="text-h5 font-weight-bold">{{ $t('survey.title') }}</span>
          </v-col>
        </v-row>
        <v-divider class="mt-3"/>
        <v-row class="justify-center">
          <v-radio-group v-model="dissatisfied" :rules="rules.dissatisfied">
            <v-radio :label="$t('survey.option1')" :value="false"/>
            <v-radio :label="$t('survey.option2')" :value="true"/>
          </v-radio-group>
        </v-row>
        <v-row v-if="dissatisfied">
          <v-col>
            <p>{{ $t("survey.reportTip1") }}</p>
            <v-textarea v-model="remark" :rules="rules.remark"/>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-spacer/>
        <v-btn :loading="loading" @click="previous" large color="warning" class="mb-3 mr-3">{{
            $t('previous')
          }}
        </v-btn>
        <v-btn v-if="dissatisfied" :loading="loading" @click="submit" large color="primary" class="mb-3">{{
            $t('submit')
          }}
        </v-btn>
        <v-btn v-else :loading="loading" @click="next" large color="primary" class="mb-3">{{
            $t('next')
          }}
        </v-btn>
        <v-spacer/>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "SurveyPage",
  computed: {
    dissatisfied: {
      get() {
        return this.$store.state.review.dissatisfied
      },
      set(val) {
        this.$store.commit('review/DISSATISFIED', val)
      }
    },
    remark: {
      get() {
        return this.$store.state.review.remark
      },
      set(val) {
        this.$store.commit('review/REMARK', val)
      }
    },
    ...mapState({
      customer: state => state.review.customer
    })
  },
  data() {
    return {
      loading: false,
      rules: {
        dissatisfied: [
          v => v !== null || this.$t("survey.dissatisfiedAlert")
        ],
        remark: [
          v => !!v || this.$t("survey.reportAlert")
        ],
      }
    }
  },
  methods: {
    previous() {
      this.$router.replace('/warranty/es')
    },
    next() {
      if (this.$refs.form.validate()) {
        this.$router.replace('/review')
      }
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true
        this.submitSurvey().then(() => {
          this.loading = false
          this.$router.replace("/finished")
        }, () => {
          this.loading = false
        })
      }
    },
    ...mapActions({
      submitSurvey: "review/submit"
    })
  },
  mounted() {
    if (!this.customer) {
      this.$router.replace("/")
    }
  }
}
</script>

<style scoped>

</style>