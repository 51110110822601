// import Vue from 'vue';

import Vue from 'vue';

const state = () => ({
    qrcodeId: 'HS',
    marketId: 'ES',
    customer: null,
    code: null,
    giftId: null,
    name: null,
    email: null,
    order: null,
    whatsapp: null,
    line: null,
    twitter: null,
    otherContact: null,
    dissatisfied: false,
    image: null,
    remark: null,
    trials: [],
});

const getters = {
};

const actions = {
    getGift: ({state}) => {
        return Vue.prototype.$axios.get(
            Vue.prototype.$format('/public/gift/{}/{}/{}',
            state.qrcodeId, state.marketId, state.code))
    },
    getCustomer: ({state}) => {
        /*return Vue.prototype.$axios.get(
            Vue.prototype.$format('/public/customer/{}/{}/{}/{}',
            state.marketId, state.name, state.email, state.order))*/
        let data = {
            qrcodeId: state.qrcodeId,
            marketId: state.marketId,
            name: state.name,
            email: state.email,
            order: state.order,
            line: state.line,
            twitter: state.twitter,
            facebook: state.facebook,
            whatsapp: state.whatsapp,
            paypal: state.paypal,
            otherContact: state.otherContact
        }

        let formData = new FormData()
        for (let key in data) {
            if (data[key]) {
                formData.append(key, data[key])
            }
        }

        return Vue.prototype.$axios.post('/public/customer', formData)
    },
    submit: ({state}) => {
        let formData = new FormData()

        formData.append('qrcodeId', state.qrcodeId)
        formData.append('marketId', state.marketId)
        formData.append('giftId', state.giftId)
        formData.append('username', state.name)
        formData.append('email', state.email)
        formData.append('order', state.order)

        if (state.line) {
            formData.append('line', state.line)
        }
        if (state.twitter) {
            formData.append('twitter', state.twitter)
        }
        if (state.whatsapp) {
            formData.append('whatsapp', state.whatsapp)
        }
        if (state.otherContact) {
            formData.append('otherContact', state.otherContact)
        }

        if (state.dissatisfied) {
            formData.append('type', '2')
            formData.append('remark', state.remark)
        } else {
            formData.append('type', '1')
            formData.append('image', state.image, state.image.name)
        }

        return Vue.prototype.$axios.post('/public/review', formData)
    },
    submitTrials: ({state}) => {
        let formData = new FormData();
        formData.append("products", state.trials);
        console.log(state.customer)

        return Vue.prototype.$axios.post(Vue.prototype.$format("/public/customer/{}/trials", state.customer.email), formData)
    },
};

const mutations = {
    CODE(state, code) {
        state.code = code
    },
    GIFT(state, giftId) {
        state.giftId = giftId
    },
    NAME(state, name) {
        state.name = name
    },
    EMAIL(state, email) {
        state.email = email
    },
    ORDER(state, order) {
        state.order = order
    },
    WHATSAPP(state, whatsapp) {
        state.whatsapp = whatsapp
    },
    LINE(state, line) {
        state.line = line
    },
    TWITTER(state, twitter) {
        state.twitter = twitter
    },
    OTHER_CONTACT(state, otherContact) {
        state.otherContact = otherContact
    },
    MARKET(state, marketId) {
        state.marketId = marketId
    },
    CUSTOMER(state, customer) {
        console.log(customer)
        state.customer = customer
    },
    IMAGE(state, image) {
        state.image = image
    },
    DISSATISFIED(state, dissatisfied) {
        state.dissatisfied = dissatisfied
    },
    REMARK(state, remark) {
        state.remark = remark
    },
    TRIALS(state, trials) {
        state.trials = trials
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}