module.exports = {
    home: {
        title: '¡Felicidades!'
    },
    redeem: {
        tip1: 'Nota: Cada cliente solo puede canjearlo una vez\nNuestra empresa se reserva el derecho a la decisión final.',
        tip2: 'Empecemos ahora.\nPor favor, ingrese su código de redención: ',
        code: 'Canjear código ',
        codeAlert: 'Por favor, ingrese el código de cupón correcto',
    },
    warranty: {
        name: 'Nombre:',
        nameAlert: 'El nombre no debe exceder los 32 caracteres.',
        email: 'Correo electrónico:',
        emailAlert: 'Por favor, ingrese una dirección de correo electrónico válida para canjear el cupón.',
        emailAlert2: 'Por favor, ingrese una dirección de correo electrónico válida para canjear el cupón.',
        order: 'Número de pedido de Amazon:',
        orderTip: 'Ejemplo: 171-1234567-1234567 (17 dígitos)',
        orderAlert: 'Por favor, ingrese su número de pedido de Amazon correcto.',
        otherContactTip: 'Nota: Por favor, deje su ID de WhatsApp o su número de teléfono móvil, en caso de que no podamos contactarlo por correo electrónico.',
        whatsapp: 'ID de WhatsApp:',
        whatsappTip: 'Ejemplo: +34 12345678',
        warningTip: 'Nota: Su información será tratada con estricta confidencialidad. No se preocupe.',
        otherContactAlert: 'Por favor, deje su ID de WhatsApp o su número de teléfono móvil, en caso de que no podamos contactarlo por correo electrónico.'
    },
    finished: {
        title1: 'Muchas gracias por el esfuerzo\n\nLe enviaremos el código de la tarjeta de regalo de Amazon para canjearlo, tan pronto como su reseña sea publicada. Normalmente, tarda 3-5 días.',
        dissatisfied: 'Muchas gracias por tomarse el tiempo de compartirnos un comentario importante.'
    },
    survey: {
        title: '¿Está satisfecho con nuestro producto?',
        option1: 'Sí, satisfecho',
        option2: 'No, no satisfecho',
        dissatisfiedAlert: '¿Está satisfecho con nuestro producto?',
        reportTip1: 'Compártanos su insatisfacción para que podamos mejorar nuestro producto. Respaldamos nuestros productos y haremos todo lo posible para resolver su problema.',
        reportAlert: 'Por favor, comparta su opinión para que podamos ayudarle.'
    },
    review: {
        title: 'Es un honor para nosotros',
        reviewTip1: '¿Podría tomarse un momento para dejar una reseña de nuestro producto? Adjunte también la captura de pantalla de su reseña.',
        reviewStep1: '1. Acceda a "Mis pedidos".',
        reviewStep2: '2. Haga clic en "Escribir reseña de cliente" debajo de "Reseñas de clientes".',
        reviewStep3: '3. Seleccione la cantidad de estrellas deseadas.\n Para las reseñas enviadas correctamente, se mostrará una marca de verificación verde.',
        reviewStep4: '4. (Opcional) Agregue texto, fotos o videos y haga clic en Enviar. (No adjunte la imagen de la tarjeta rasca y gana.)',
        reviewStep5: '5. Envíenos capturas de pantalla de su reseña, para que se diferencie de otras.',
        reviewStep41: 'Variante Captura de pantalla ①',
        reviewStep42: 'Variante Captura de pantalla ②',
        reviewStep43: 'Variante Captura de pantalla ③',
        reviewTip2: 'Le enviaremos el cupón para canjearlo tan pronto como su reseña sea publicada en la página de reseñas de Amazon.',
        reviewTip3: 'Normalmente, tarda 2-5 días.',
        imageTip: 'Haga clic aquí para proporcionar la captura de pantalla de su reseña',
        imageAlert: 'Verifique la imagen solicitada.',
        dialog: {
            title: 'Confirmación',
            cancel: 'Incorrecto, enviar de nuevo',
            submit: 'enviar'
        }
    },
    alert: {
        90001: 'Este número de pedido ya está registrado',
        90002: 'Asegúrese de que su pedido se realizó en el marketplace de Amazon.de.',
        90003: 'Su reseña está pendiente en Amazon. Normalmente, tarda 3-5 días. Por favor, tenga paciencia.',
        90004: 'Cada cliente solo puede canjearlo una vez. Gracias por su comprensión.',
        90005: 'Muchas gracias por su continuo apoyo. Disculpe, pero su número de pedido no es elegible para la promoción de la tarjeta rasca y gana. (Las personas que participan en el concurso a través de un tweet o solicitan soporte al cliente no pueden participar en el concurso). Sin embargo, tenga en cuenta que no podremos atender esta solicitud en el futuro. Le agradecemos su comprensión.',
    },
    next: 'Siguiente',
    previous: 'Anterior',
    submit: 'Confirmar',
}
